.bookmarking {
  &.card_view {
    background-position: 0 0;
    display: inline-block;
    border: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 17px;
    background-size: cover;
    background-repeat: no-repeat;

    .add_bookmark,
    .remove_bookmark {
      float: none;
      padding: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    &.added {
      background-position: 0 -20px;
    }
  }

  &.old-favorite-buttons {
    background-image: url('/static/images/buttons-favorite.svg');
  }

  &.new-favorite-buttons {
    background-image: url('/static/images/item/buttons-favorite.svg');
    top: 22px;
    right: 15px;
  }

  &.bordered-favorite-buttons {
    background-image: url('/static/images/item/buttons-favorite-bordered.svg');
  }
}
